import React from 'react';

interface Props {
  buttonOnClikc: Function
}

const RegSuccess: React.FC<Props> = ({ buttonOnClikc }) => {
  return (
    <div className='result'>
      <h1>Palju õnne!</h1>
      <p><strong>Liitudes uudiskirjaga<br/>osaled kinkekoti loosis.</strong></p>
      <p>Vali sobiv automaat ja sisesta enda e-post<br/>ja telefoni number. Nii saame võidu korral<br/>auhinna sulle saata.</p>
      <input type={'email'} name={'email'}/>
      <input type={'text'} name='automat'/>
      <input type={'tel'} name='tel'/>
      <p><button onClick={ () => buttonOnClikc(true) } >Osalen Loosimises</button></p>
    </div>
  );
}

export default RegSuccess;