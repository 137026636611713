import React, { useState, useRef, useEffect } from 'react';
import { wrapLetters } from '../tools/tools';
import './assets/lehe-app.scss';
import REG1 from './ContentReg1';
import REG2 from './ContentReg2';
import RegSuccess from './ContentRegSuccess';
import RegFail from './ContentRegFail';
import PayingGame from './ContentPayingGame';
import Loading from './Loading';
import Welcome from './ContentWelcome';

interface Props {}

enum VIEWS {
  LOADING = 0,
  WELCOME = 1,
  RULES = 2
}

enum CONTENT {
  WELCOME = 0,
  REGISTER1 = 1,
  REGISTER2 = 2,
  SUCCESSRESULT = 3,
  FAILRESULT = 4
}

const animationTime = 2000;
let preLoaded = 0;
const cdnUrl = 'https://dev.code-essence.eu/lidl/media/';
const preloadData = [
  'bottom.png',
  'top-left.png',
  'top-right.png',
  'steak-1.png',
  'leaf-2.png',
  'leaf-3.png',
  'leaf-1.png'
];

const LeheApp: React.FC<Props> = () => {

  useEffect(() => {
    //lets preload everyhing first
    preloadData.forEach((image) => {
      const img = new Image();
      img.src = cdnUrl + image;
      img.onload = () => preLoaded++;
    });
    preloadCheck();
  })
  const [currentView, setView] = useState(0);
  const [currentContent, setContent] = useState(0);  
  const [isPlayingGame, setPlayingGame] = useState(false);    
  const loaderRef = useRef<HTMLDivElement>(null);  
  const viewRef = useRef<HTMLDivElement>(null);    


  const preloadCheck = () => {
    preLoaded === preloadData.length ? startApp() :  setTimeout(() => preloadCheck(), 2000);
  }
  const gamePlay = () => {
    setContent(Math.random() > 0.5 ? CONTENT.FAILRESULT : CONTENT.SUCCESSRESULT);
    setPlayingGame(false);
  }
  const registerGame = () => {
    setContent(CONTENT.REGISTER1);
  }

  let currentTimer : any;
  const startApp = () => {
    if(currentView !== 0) return;
    loaderRef.current?.classList.add('bounce-out');
    currentTimer = setTimeout( () => {
      setView(VIEWS.WELCOME);
      currentTimer =  setTimeout( () => {
        setContent(CONTENT.REGISTER1);
      }, animationTime * 2 + 100);
    }, animationTime + 100);
    //
    console.log(currentTimer);
  }



  return (
    <div className='main-app'>
      <div className='app-container'>
        { currentView !== VIEWS.LOADING &&
          <>
          <div className='backstage'>
            <span className='tree-top tree-top__right'></span>
            <span className='tree-top tree-top__left'></span>
          </div>
          <div className={`leafs ${isPlayingGame ? 'leafs__active' : ''}`}>
            <span className="leaf leaf1" onClick={gamePlay}></span>
            <span className="leaf leaf2" onClick={gamePlay}></span>
            <span className="leaf leaf3" onClick={gamePlay}></span>
          </div>
          </> 
        }
        { currentView === VIEWS.LOADING && 
          <div className='loader'><div ref={loaderRef}><Loading></Loading></div></div>
        }
        { currentView === VIEWS.WELCOME && 
          <div className='content'>
            <div ref={viewRef} className='bounce-in'>
              { 
                isPlayingGame ? <PayingGame></PayingGame> 
                :
                <>
                  { currentContent === CONTENT.WELCOME && <Welcome></Welcome> }
                  { currentContent === CONTENT.REGISTER1 && <REG1 buttonOnClikc={ () => setPlayingGame(true) }></REG1> }
                  { currentContent === CONTENT.REGISTER2 && <REG2 buttonOnClikc={ () => setPlayingGame(true) }></REG2> }                
                  { currentContent === CONTENT.SUCCESSRESULT && <RegSuccess buttonOnClikc={registerGame}></RegSuccess> }
                  { currentContent === CONTENT.FAILRESULT && <RegFail buttonOnClikc={() => setPlayingGame(true)}></RegFail> }

                </>
              }
            </div>
          </div>
        }

        <button onClick={ () => setView(VIEWS.LOADING) }>Loading</button>
        <button onClick={ () => setView(VIEWS.WELCOME) }>WELCOME</button>         
      </div>      
    </div>
  );
}

export default LeheApp;